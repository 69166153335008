.switch-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30px;
    background-color: #e0e0e0;
    border-radius: 50px;
    position: relative;
    padding: 5px 20px;
    cursor: pointer;
    height: 16px;
}

.circle {
    width: 25px;
    height: 25px;
    background-color: #1e7aa8;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
}

.circle.left {
    left: 0px;
}

.circle.right {
    right: 0px;
}
