.map-container {
    width: 100%;
    margin: 0 auto;
    height: 520px;
}

.address-input {
    width: 94%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.suggestions-container {
    margin-bottom: 20px;
}

.suggestion {
    padding: 8px 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    transition: background-color 0.2s;
}

.suggestion:hover {
    background-color: #f7f7f7;
}

.map {
    width: 100%;
    height: 473px;
    transition: height 0.3s;
    /* Transición suave al cambiar el tamaño */
}

.map-hidden {
    height: 0;
    overflow: hidden;
}