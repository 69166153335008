@keyframes rainbow-animation {
  0%, 100% { color: #774cac; }
  50% { color: #4669E8; }
  25%, 75% { color: #8753b1; }
  75% { color: #4669E8; }
}

.rainbow-text {
  animation: rainbow-animation 5s linear infinite;
}

.slick-dots {
  bottom: 4px; 
}
.slick-dots li button:before {
  color: #d4d3d6; 
}

.slick-dots li.slick-active button:before {
  color: #666666; 
}

.gradient-background {
  background: linear-gradient(to bottom, #ffffff 20%, #DFEDF9 70%, #ffffff 100%);
  min-height: 100vh;
}

.my-swal {
  z-index: 9999 !important;
}
