.Loader {
  position: fixed; /* Asegura que el componente se superponga al contenido */
  top: 50%; /* Centra verticalmente en la mitad de la pantalla */
  left: 50%; /* Centra horizontalmente en la mitad de la pantalla */
  transform: translate(-50%, -50%); /* Centra correctamente el componente */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo semitransparente para enfocar la atención en el loader */
  z-index: 1000; /* Asegura que esté en la parte superior de otros elementos */
  width: 100%; /* Ocupa todo el ancho de la pantalla */
  height: 100%; /* Ocupa todo el alto de la pantalla */
}


.logo {
  width: 100px; /* Ajusta el tamaño de tu logotipo según sea necesario */
  height: auto;
}