@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Quicksand-Bold";
  src: local("Quicksand"), url(./assets/fonts/Quicksand-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Quicksand-Light";
  src: local("QuicksandL"), url(./assets/fonts/Quicksand-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Quicksand-Regular";
  src: local("QuicksandR"), url(./assets/fonts/Quicksand-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Quicksand-Medium";
  src: local("QuicksandR"), url(./assets/fonts/Quicksand-Medium.ttf) format("truetype");
}
